import React, { useState, useEffect, useCallback } from 'react';
import { Alert, Col, Row } from "reactstrap";
import { NewLoader } from "../../Basicos/index";
import { CardInfoCalendarioPadrao, CardAddAtividadePadrao, CardCalendarioPadrao } from '.';
import pick from 'lodash/pick';
import { axiosApi } from '../../Basicos/axiosInstances';
import { connect } from 'react-redux';
import { ativarModalConfirm, ativarAlert } from '../../Store/Actions';
import ModalDetalhes from '../../Modal/ModalDetalhes';
import ModalAlert from '../../Modal/ModalAlert';

const inicioDaSemana = 1;
const finalDaSemana = 5;

function CardsCadastroDeAtividades({
    match: {
        params: {
            periodo_letivo_id,
            tema_id
        }
    },
    subFuncionalidade: {
        url,
        async,
        dados,
        descricao,
        tipo_de_datas,
        campos,
        acoes,
        ...resto
    },
    ativarCofirmacao,
    ativar
}) {

    const [isLoading, setIsLoading] = useState(true);
    const [informacoesDoCalendario, setInformacoesDoCalendario] = useState({});
    const [horariosDefinidos, setHorariosDefinidos] = useState([]);
    const [infoHorarioSelecionado, setInfoHorarioSelecionado] = useState({});
    const [sugestao, setSugestao] = useState([]);
    const [sugestaoFim, setSugestaoFim] = useState([]);
    const [chave, setChave] = useState("");
    const [detalhes, setDetalhes] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const getHorariosDoCurso = () =>
        informacoesDoCalendario.tema && informacoesDoCalendario.tema.curso && informacoesDoCalendario.tema.curso.horarios
            ? informacoesDoCalendario.tema.curso.horarios
            : [];

    const getTipoDeAtividadeDoTema = () =>
        informacoesDoCalendario.tiposDeAtividades && informacoesDoCalendario.tiposDeAtividades
            ? informacoesDoCalendario.tiposDeAtividades
            : [];

    const downHandler = useCallback(
        ({ key }) => setChave(key),
        []
    )

    useEffect(() => {
        if (chave === "Delete")
            chamaDeleta(infoHorarioSelecionado)
    }, [chave, infoHorarioSelecionado]);

    useEffect(() => {
        document.addEventListener('keydown', downHandler);
        const buscarDados = async () => {
            setIsLoading(true);
            try {
                const result = await axiosApi({
                    method: 'GET',
                    url,
                    params: {
                        periodo_letivo_id,
                        tema_id
                    }
                });
                const { semanapadrao, tema, periodo, tiposDeAtividades, datainicial, datafinal } = result.data.dados;
                setSugestao(datainicial)
                setSugestaoFim(datafinal)
                setInformacoesDoCalendario({ tema, periodo, tiposDeAtividades });
                setHorariosDefinidos(semanapadrao ? semanapadrao.horarios : []);
                setInfoHorarioSelecionado((prevState) => ({ ...prevState, data_inicio: datainicial, data_fim: datafinal, estruturasNoMesmoDia: [] }));

            } catch (error) {

            }

            setIsLoading(false);
        };
        buscarDados();

        return () => {
            document.removeEventListener('keydown', downHandler);
        }
    }, [downHandler]);

    const horariosDivergentes = () => {
        if (horariosDefinidos.length) {
            horariosDefinidos.reduce((curr, next) => {
                if (curr.data_inicio != next.data_inicio ||
                    curr.data_fim != next.data_fim) {
                    setIsOpen(true);
                }
                return next
            })
        }
    }

    useEffect(() => {
        horariosDivergentes()
    }, [horariosDefinidos]);

    const selecionarHorario = infoHorario => setInfoHorarioSelecionado({ ...infoHorario, data_inicio: infoHorario.data_inicio ?? sugestao, data_fim: infoHorario.data_fim ?? sugestaoFim });

    const procurarHorarioComMesmaAtividadeNoDia = ({ tipo_atividade_id, estruturasNoMesmoDia }) => {
        // && tipo_atividade_id != infoHorarioSelecionado.tipo_atividade_id
        if (tipo_atividade_id && estruturasNoMesmoDia) {
            return estruturasNoMesmoDia.find(elemento => elemento.tipo_atividade_id === tipo_atividade_id);
        }
        return undefined;
    };

    const procurarHorarioComMesmoDiaEHorario = ({ dia_da_semana, horario_do_curso_id, tipo_atividade_id }) => {
        // && tipo_atividade_id != infoHorarioSelecionado.tipo_atividade_id
        let horario = { estruturasNoMesmoDia: [] };

        horariosDefinidos.forEach((elemento, indice) => {
            if (elemento.dia_da_semana === dia_da_semana && elemento.horario_do_curso_id === horario_do_curso_id) {
                if (elemento.tipo_atividade_id === tipo_atividade_id) {
                    horario = { ...elemento, ...horario };
                }
                horario.estruturasNoMesmoDia.push(elemento);
            }
        });

        return horario;
    };

    const verificarMudancaEstado = (novo, antigo) => {
        return novo && novo !== antigo;
    };

    const verificarHorarioEDiaSemana = (valores) => {
        let infoHorario = { ...infoHorarioSelecionado, ...valores };

        if (verificarMudancaEstado(valores.horario_do_curso_id, infoHorarioSelecionado.horario_do_curso_id) || verificarMudancaEstado(valores.dia_da_semana, infoHorarioSelecionado.dia_da_semana)) {
            return {
                ...procurarHorarioComMesmoDiaEHorario(infoHorario),
                ...pick(infoHorario, ["dia_da_semana", "data_inicio", "data_fim", "horario_do_curso_id", "tipo_atividade_id"]),

            };

        }

        return infoHorario;
    };

    const onChangeHorario = (valores) => {

        const infoHorarioSelecionadoAux = verificarHorarioEDiaSemana(valores);

        const horarioComMesmaAtividade = procurarHorarioComMesmaAtividadeNoDia(infoHorarioSelecionadoAux);
        infoHorarioSelecionadoAux.indiceArray = horarioComMesmaAtividade ? horarioComMesmaAtividade.indiceArray : undefined;
        setInfoHorarioSelecionado(infoHorarioSelecionadoAux);

    };

    const salvarHorario = () => {
        const infoHorario = { ...infoHorarioSelecionado };

        const horariosDefinidosAux = horariosDefinidos.slice();

        if (infoHorario.indiceArray !== undefined) {
            horariosDefinidosAux[infoHorario.indiceArray] = infoHorario;
        } else {
            infoHorario.indiceArray = horariosDefinidos.length;
            infoHorario.estruturasNoMesmoDia.push(infoHorario);
            horariosDefinidosAux.push(infoHorario);
            setInfoHorarioSelecionado({ ...infoHorario });
        }

        axiosApi({
            method: "post",
            url,
            data: { ...infoHorario, periodo_letivo_id: parseInt(periodo_letivo_id), tema_id: parseInt(tema_id), estruturasNoMesmoDia: undefined }
        }).then(resp => {
            let infoHorarioAux = { ...infoHorario, id: resp.data.dados.horario_semana_padrao_id };
            horariosDefinidosAux[infoHorarioAux.indiceArray] = infoHorarioAux;
            setInfoHorarioSelecionado(infoHorarioAux);
            ativar(201, resp.data.sucesso)
            setHorariosDefinidos(horariosDefinidosAux);
        }).catch(error => {
            ativar(400, error.response.data.erro);
        })

    }

    const deletarHorario = (infoHorario) => {
        if (infoHorario.id !== undefined) {
            axiosApi.delete(
                `${url}/${infoHorario.id}`
            ).then(resp => {
                const horariosDefinidosAux = horariosDefinidos.slice();

                for (var i = 0; i < horariosDefinidosAux.length; i++)
                    if (infoHorario.id === horariosDefinidosAux[i].id)
                        horariosDefinidosAux.splice(i, 1);

                setHorariosDefinidos(horariosDefinidosAux);
            })
        }
    }

    const chamaDeleta = infoHorario =>
        ativarCofirmacao({ titulo: 'deletar', mensagem: 'Deseja remover este horário?', callback: () => deletarHorario(infoHorario) });
    return (
        <NewLoader isActive={isLoading} overlay >
            <ModalAlert setIsOpen={setIsOpen} isOpen={isOpen} titulo={'Alerta'} mensagem={"Existem datas de início e fim divergentes"} />
            <Row className="">
                <Col xs={12} sm={12} md={4} lg={4} xl={3} >
                    <Row>
                        <Col sm={12} md={12} className="mb-3">
                            <CardInfoCalendarioPadrao
                                informacoesDoCalendario={informacoesDoCalendario}
                                setDetatelhes={setDetalhes}

                            />
                        </Col>
                        <Col sm={12} md={12}>
                            <CardAddAtividadePadrao
                                infoHorarioSelecionado={infoHorarioSelecionado}
                                salvarHorario={salvarHorario}
                                onChangeHorario={onChangeHorario}
                                horariosCurso={getHorariosDoCurso()}
                                tipoAtividades={getTipoDeAtividadeDoTema()}
                                inicioDaSemana={inicioDaSemana}
                                finalDaSemana={finalDaSemana}
                                sugestao={sugestao}

                            />
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} sm={12} md={8} lg={8} xl={9} >
                    <CardCalendarioPadrao
                        className="h-100"
                        horariosDefinidos={horariosDefinidos}
                        horarioSelecionado={infoHorarioSelecionado}
                        deletarHorario={chamaDeleta}
                        horariosCurso={getHorariosDoCurso()}
                        tipoAtividades={getTipoDeAtividadeDoTema()}
                        selecionarHorario={selecionarHorario}
                        setInfoHorarioSelecionado={setInfoHorarioSelecionado}
                        sugestao={sugestao}
                    />
                </Col>
            </Row>
            <ModalDetalhes
                detalhes={detalhes}
                setDetalhes={setDetalhes}
                informacoes={informacoesDoCalendario?.tema?.atividades}
                periodo={informacoesDoCalendario?.periodo}
                horario={horariosDefinidos}
            />
        </NewLoader>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        ativar: (tipo, acao) => dispatch(ativarAlert(tipo, acao)),
        ativarCofirmacao: (obj) => dispatch(ativarModalConfirm(obj))
    }
}

export default connect(null, mapDispatchToProps)(CardsCadastroDeAtividades);
