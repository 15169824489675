import React, { useState, useEffect } from 'react';
import { Col, Row, Modal, ModalBody, Button } from 'reactstrap';
import { TableWithVirtualizedRow } from '../Basicos';
import { axiosApi } from '../Basicos/axiosInstances';
import { dateToPtBr } from '../Basicos/funcoes';


export default function ModalConteudo({
    isOpen, setModalConteudo, id
}) {
    const [prova, setProva] = useState();

    const getConteudo = async () => {
        if (isOpen) {
            console.log('aqui')
            console.log(id)
            const { data } = await axiosApi.get(`semana-padrao/cadastro-de-ofertas/conteudo-prova/${id}`);
            setProva(data.dados)
        }
    }

    useEffect(() => {
        getConteudo();
    }, [isOpen == true]);

    const fecharModalConteudo = () => {
        setProva(undefined);
        setModalConteudo(false);
    }

    return (
        prova != undefined ?
            <Modal className="modal-conteudo" isOpen={isOpen}>
                <ModalBody>
                    <Row className="titulo-modal-critica">
                        {prova.length == 0 ?
                            <Row>
                                <h4><b>Conteúdo da Prova</b></h4>
                            </Row>
                            :
                            <>
                                <Row>
                                    <h4><b>Conteúdo da Prova - {prova?.[0]?.prova.aula.titulo}</b></h4>
                                </Row>
                                <Row>
                                    <h6><b>{prova?.[0]?.semestre_periodo_letivo.periodo_letivo.descricao} - {prova?.[0]?.semestre_periodo_letivo.semestre.descricao} - {prova?.[0]?.prova.aula.tema?.nome} - {prova?.[0]?.prova.aula.tipo_de_atividade?.nome} - {prova?.[0]?.prova.aula.tipo_de_atividade?.atividade_principal?.nome} - {dateToPtBr(prova?.[0]?.prova.horario_periodo.data)}</b></h6>
                                </Row>
                            </>
                        }
                    </Row>
                    <div className='div-de-teste'>
                        <TableWithVirtualizedRow
                            tabelaClassName="tabelaConsulta"
                            isConteudoProva={true} //variavel para saber se vem da tela de conteudo prova
                            columns={[
                                {
                                    Header: 'Aula',
                                    accessor: 'aula.titulo',
                                    disabled: true,
                                    style: { width: '500px' },
                                    id: 'nome_professor',
                                },
                                {
                                    Header: 'Data',
                                    accessor: 'datas_unicas',
                                    disabled: true,
                                    style: { width: '150px' },
                                    id: 'array_data',
                                },
                                {
                                    Header: 'Professor',
                                    accessor: 'professores_unicos',
                                    disabled: true,
                                    arrayField: true,
                                    style: { width: '350px' },
                                    id: 'array_professor',
                                    className: 'array_professor'
                                }
                            ]}
                            data={prova}
                        />
                    </div>
                    <Col sm={12} className="align-content-middle mb-3 p-0">
                        <Button
                            onClick={() => fecharModalConteudo(false)}
                            className="float-right btn-transparente"
                        >
                            Fechar
                        </Button>
                    </Col>
                </ModalBody>
            </Modal>
            : ''
    );
}
