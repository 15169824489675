import React, { Fragment, useEffect, useState } from 'react';
import { Col, Button, Row } from "reactstrap";
import { DateForm, InputForm, SelectForm, TableWithVirtualizedRow } from '../Basicos';
import { formatLaravelDate, parseDate } from '../Basicos/funcoes';
import { axiosApi } from '../Basicos/axiosInstances';
import { connect } from 'react-redux';
import * as actionsRedux from '../Store/Actions/index';
import CardHoc from '../Hoc/cardHoc';
import FileServer from 'file-saver';
import { AiFillFileExcel } from 'react-icons/ai';

function CalendarioAvaliacaoAnalitica({
    ativar,
    subFuncionalidade: {
        url,
        tipoatividade,
        descricao
    },
}) {
    //Alimenta as opções
    const [periodoLetivoOptions, setPeriodoLetivoOptions] = useState();
    const [semestreOptions, setSemestreOptions] = useState();
    const [moduloOptions, setModuloOptions] = useState();

    const [valorPeriodoLetivo, setValorPeriodoLetivo] = useState();
    const [valorModulo, setValorModulo] = useState();
    const [valorProfessor, setValorProfessor] = useState();
    const [valorTituloProva, setValorTituloProva] = useState();

    const [semestreIds, setSemestreIds] = useState([]);
    const [dataInicio, setDataInicio] = useState();
    const [dataFim, setDataFim] = useState();

    const [disponiveis, setDisponiveis] = useState([]);

    const loadInfosAnalitico = async () => {
        const response = await axiosApi.get(`semana-padrao/prova-analitica`);
        gerarSelectOptions(response.data.filtros.modulos, response.data.filtros.periodos_letivos, response.data.filtros.semestres);
    }

    const gerarSelectOptions = (modulos, periodos_letivos, semestres) => {
        setPeriodoLetivoOptions(periodos_letivos && periodos_letivos.map(element => ({ label: element.descricao, value: element.id })));
        setSemestreOptions(semestres)
        setModuloOptions([
            { label: 'Todos', value: '0' },
            ...modulos.map(element => ({ label: element.nome, value: element.id }))
        ]);
    }

    useEffect(() => {
        loadInfosAnalitico();
    }, []);

    const getDadosFiltrados = async () => {
        if (valorPeriodoLetivo == undefined)
            ativar(400, 'Selecione um período letivo!')
        else if (semestreIds.length == 0)
            ativar(400, 'Selecione pelo menos um semestre!')
        else {
            const response = await axiosApi.get(`semana-padrao/filtro-prova-analitica?nome_professor=${valorProfessor}&periodo_letivo_id=${valorPeriodoLetivo?.value}&semestresIds[]=${semestreIds}&modulo_id=${valorModulo?.value}&data_inicio=${dataInicio}&data_fim=${dataFim}&titulo_prova=${valorTituloProva}`)
            setDisponiveis(response.data.dados)
        }
    }

    const onChangeSelect = (nome, valor) => {
        switch (nome) {
            case 'periodo_letivo_id':
                let periodoLetivo = periodoLetivoOptions.find((element) => element.value === valor);
                setValorPeriodoLetivo(periodoLetivo)
                break;
            case 'modulo_id':
                let modulo = moduloOptions.find((element) => element.value === valor);
                setValorModulo(modulo)
                break;
            case 'semestres_ids':
                setSemestreIds(valor);
                break;
            case 'professor':
                setValorProfessor(valor);
                break;
            case 'titulo_prova':
                setValorTituloProva(valor);
                break;
            default:
                break;
        }
    }

    const onChangeHorario = (intervalo, valores) => {
        if (valores.from != undefined)
            setDataInicio(valores.from)
        if (valores.to != undefined)
            setDataFim(valores.to)
    };

    const limparFiltro = () => {
        setValorPeriodoLetivo(null);
        setValorModulo(null);
        setValorProfessor('');
        setValorTituloProva('');
        setSemestreIds([]);
        setDataInicio(undefined);
        setDataFim(undefined);
        setDisponiveis([]);
    }

    const getExportDownload = async () => {
        try {
            let params = {
                periodo_letivo_id: valorPeriodoLetivo?.value,
                semestresIds: semestreIds,
                nome_professor: valorProfessor,
                modulo_id: valorModulo?.value,
                data_inicio: dataInicio ?? null,
                data_fim: dataFim ?? null,
                titulo_prova: valorTituloProva
            }
            // setIsLoading(true);
            await axiosApi.post(`exportacao/xlsx/avaliacao-analitica`, params, {
                responseType: 'blob',
            }).then((res) => {
                const fileName = `Avaliação Analítica.xlsx`;
                const file = new File([res.data], fileName, { type: `${res?.data?.Blob?.type};charset=utf-8` });
                FileServer.saveAs(file);
            });
        } catch (error) {
            console.log(error);
        } finally {
            // setIsLoading(false);
        }
    }

    const semestresIdsOptions = () => semestreOptions?.map(grupo => ({ value: grupo.id, label: grupo.descricao }));
    const semestreIdsValue = () => semestresIdsOptions()?.filter(elemento => semestreIds?.includes(elemento.value));

    return (
        <Fragment>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="d-flex mb-3 header-legenda text-header">
                <h3><strong> {descricao.toUpperCase()} </strong></h3>
            </Col>
            <Col sm={12} md={12}>
                <Row className="pr-5 px-3">
                    <SelectForm
                        label="Período Letivo*"
                        name="periodo_letivo_id"
                        placeholder="Período Letivo"
                        options={periodoLetivoOptions}
                        value={valorPeriodoLetivo ?? ''}
                        onChange={onChangeSelect}
                        style={
                            {
                                col: {
                                    sm: 2,
                                    xs: 2,
                                    md: 2,
                                    lg: 2,
                                    xl: 2
                                }

                            }}
                    />
                    <SelectForm
                        telaAgenda={true}
                        label="Semestre*"
                        name="semestres_ids"
                        value={semestreIdsValue()}
                        options={semestresIdsOptions()}
                        onChange={onChangeSelect}
                        placeholder="Semestre"
                        isMulti
                        style={
                            {
                                col: {
                                    sm: 2,
                                    xs: 4,
                                    md: 4,
                                    lg: 4,
                                    xl: 4
                                }

                            }}
                    />
                    <SelectForm
                        label="Módulo"
                        name="modulo_id"
                        options={moduloOptions}
                        onChange={onChangeSelect}
                        value={valorModulo ?? ''}
                        placeholder="Módulo"
                        style={
                            {
                                col: {
                                    sm: 2,
                                    xs: 2,
                                    md: 2,
                                    lg: 2,
                                    xl: 2
                                }

                            }}
                    />
                    <InputForm
                        type="text"
                        name="professor"
                        label="Professor"
                        placeholder="Professor"
                        value={valorProfessor ?? ''}
                        onChange={onChangeSelect}
                        style={
                            {
                                col: {
                                    sm: 2,
                                    xs: 4,
                                    md: 4,
                                    lg: 4,
                                    xl: 4
                                }

                            }}
                    />
                    <InputForm
                        type="text"
                        name="titulo_prova"
                        label="Título Prova"
                        placeholder="Título Prova"
                        value={valorTituloProva ?? ''}
                        onChange={onChangeSelect}
                        style={
                            {
                                col: {
                                    sm: 2,
                                    xs: 4,
                                    md: 4,
                                    lg: 4,
                                    xl: 4
                                }

                            }}
                    />

                    <DateForm
                        name="intervalo_data"
                        label="Tipo de Marcador"
                        style={{ labelClass: "label-centralizado", telaAgenda: false, telaAvaliacao: true }}
                        onChange={onChangeHorario}
                        dataInicial={{ "label": "Data Inicial", ...dateInicialFormStyle, diasFuturos: true }}
                        dataFinal={{ "label": "Data Final", ...dateFinalFormStyle, diasFuturos: true }}
                        valorInicial={dataInicio}
                        valorFim={dataFim}
                        value={{ from: parseDate(dataInicio, formatLaravelDate), to: parseDate(dataFim, formatLaravelDate) }}
                    />
                    <Col className="btn-limpar-filtro-agenda">
                        <Button
                            className="mx-3 py-2 pesquisar-agenda"
                            onClick={getDadosFiltrados}
                        >Pesquisar</Button>
                    </Col>
                    <Col className="btn-limpar-filtro-agenda">
                        <Button
                            className="mx-3 py-2 pesquisar-agenda"
                            onClick={limparFiltro}
                        >Limpar</Button>
                    </Col>
                </Row>
            </Col>

            <Col xs={13} sm={13} md={13} lg={13} xl={13} className={'pr-5 tabela-avaliacao-analitica'}>
                <TableWithVirtualizedRow
                    tabelaClassName="tabelaConsulta"
                    columns={[
                        {
                            Header: 'Semestre',
                            accessor: 'semestre',
                            disabled: true,
                            style: { width: '100px' },
                        },
                        {
                            Header: 'Tema',
                            accessor: 'tema',
                            disabled: true,
                            style: { width: '230px' },
                        },
                        {
                            Header: 'Módulo',
                            accessor: 'modulo',
                            disabled: true,
                            style: { width: '100px' },
                        },
                        {
                            Header: 'Tipo de Atividade',
                            accessor: 'tipo_atividade',
                            disabled: true,
                            style: { width: '200px' },
                        },
                        {
                            Header: 'Titulo Prova',
                            accessor: 'titulo_prova',
                            disabled: true,
                            style: { width: '350px' },
                        },
                        {
                            Header: 'Professores Prova',
                            accessor: 'professores_prova',
                            disabled: true,
                            style: { width: '400px' },
                        },
                        {
                            Header: 'Local Prova',
                            accessor: 'local_prova',
                            disabled: true,
                            style: { width: '200px' },
                        },
                        {
                            Header: 'Data Prova',
                            accessor: 'data_prova',
                            disabled: true,
                            style: { width: '110px' },
                        },
                        {
                            Header: 'Titulo Aula',
                            accessor: 'titulo_aula',
                            disabled: true,
                            style: { width: '350px' },
                        },
                        {
                            Header: 'Turma',
                            accessor: 'turma',
                            disabled: true,
                            style: { width: '100px' },
                        },
                        {
                            Header: 'Local Aula',
                            accessor: 'local_aula',
                            disabled: true,
                            style: { width: '200px' },
                        },
                        {
                            Header: 'Início Aula',
                            accessor: 'inicio_aula',
                            disabled: true,
                            style: { width: '110px' },
                        },
                        {
                            Header: 'Data Aula',
                            accessor: 'data_aula_formatado',
                            disabled: true,
                            style: { width: '110px' },
                        },
                        {
                            Header: 'Professores Aula',
                            accessor: 'professores_aula',
                            disabled: true,
                            style: { width: '400px' },
                        },
                        {
                            Header: 'Objetivo Aula',
                            accessor: 'objetivo_aula',
                            disabled: true,
                            style: { width: '200px' },
                        },
                    ]}
                    data={disponiveis}
                />
            </Col>
            {
                disponiveis.length > 0 ?
                    <div className='botao-excel-analitica'>
                        <Button
                            className="float-right export-btnConsulta"
                            size="sm"
                            onClick={() => getExportDownload('xlsx')}
                        >
                            <AiFillFileExcel size="1.2rem" style={{ padding: '1px' }} />
                            Exportar XLSX
                        </Button>
                    </div>
                    : ""
            }
        </Fragment>
    );
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        atualizarDadosRemotamente: (params) => dispatch(actionsRedux.atualizarSubFuncionalidade(ownProps.funcionalidade, ownProps.indiceSubFuncionalidade, params)),
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
    }
}

const mapStateToProps = (state) => {
    return {
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
        exibirCalendario: true
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardHoc(CalendarioAvaliacaoAnalitica))

const dateInicialFormStyle = {
    "placeholder": "Data Inicial",
    "style": {
        "labelClass": "label-conteudoInferior",
        "elementoClass": "",
        "col": {
            "sm": 3
        }
    }
}
const dateFinalFormStyle = {
    "placeholder": "Data Final",
    "style": {
        "labelClass": "label-conteudoInferior",
        "elementoClass": "",
        "col": {
            "sm": 3
        }
    }
}