import React, { PureComponent } from 'react';
import { BrowserRouter as Router, Redirect, Switch, Route } from "react-router-dom";
import { Container, Row } from 'reactstrap';
import {
    Sidebar, Login, Auxiliares, Professores, Alunos, AtividadeExternas, Consultas, Tema, Local,
    NoticiasEEventos, SemanaPadrao, Noticia, Import, Dashbord
} from './componentes/Funcionalidades/index';

import { Alert, Loader } from './componentes/Basicos/index';
import { ErroAcesso, VerificarErroInterno } from './componentes/Basicos/Erros/index';
import { connect } from 'react-redux';
import * as actionsRedux from './componentes/Store/Actions/index';
import ModalConfirm from './componentes/Modal/ModalConfirm';
import { convertToSlug } from './componentes/Basicos/funcoes'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './componentes/css/default.css';
import './componentes/css/cores.css';

class App extends PureComponent {

    constructor(props) {
        super(props)
        this.props.estadoDaAutenticacao();
        this.props.alterarTamanhoJanela(window);
        window.addEventListener('resize', (window) => this.props.alterarTamanhoJanela(window.currentTarget));
    }

    componentWillUnmount = () => window.removeEventListener('resize', this.props.alterarTamanhoJanela);

    verificarAutenticacao = () => {
        const { funcionalidades, token, verificandoLocal, sidebarAtivo, loader } = this.props;
        if (token) {
            return (
                <Row noGutters className="no-padding h-100">
                    <Sidebar funcionalidades={funcionalidades} />
                    <Container fluid className={`no-padding h-100 principal ${sidebarAtivo ? 'active' : ''}`}>
                        <Switch>
                            {
                                funcionalidades.map(elemento => {
                                    switch (convertToSlug(elemento.descricao)) {
                                        case "professores":
                                            return (
                                                <Route
                                                    key={elemento.url}
                                                    path={elemento.url}
                                                    component={Professores}
                                                />
                                            );
                                        case "atividades-externas":
                                            return (
                                                <Route
                                                    key={elemento.url}
                                                    path={elemento.url}
                                                    component={AtividadeExternas}
                                                />
                                            );
                                        case "locais-de-atividades":
                                            return (
                                                <Route
                                                    key={elemento.url}
                                                    path={elemento.url}
                                                    component={Local}
                                                />
                                            );
                                        case "alunos":
                                            return (
                                                <Route
                                                    key={elemento.url}
                                                    path={elemento.url}
                                                    component={Alunos}
                                                />
                                            );
                                        case "auxiliares":
                                            return (
                                                <Route
                                                    key={elemento.url}
                                                    path={elemento.url}
                                                    component={Auxiliares}
                                                />
                                            );
                                        case "semana-padrao":
                                            return (
                                                <Route
                                                    key={elemento.url}
                                                    path={elemento.url}
                                                    component={SemanaPadrao}
                                                />
                                            );
                                        case "eventos-e-noticias":
                                            return (
                                                <Route
                                                    key={elemento.url}
                                                    path={elemento.url}
                                                    component={NoticiasEEventos}
                                                />
                                            );
                                        case "dashboards":
                                            return (
                                                <Route
                                                    key={elemento.url}
                                                    path={elemento.url}
                                                    component={Dashbord}
                                                />
                                            );

                                        case "consultas":
                                            return (
                                                <Route
                                                    key={elemento.url}
                                                    path={elemento.url}
                                                    component={Consultas}
                                                />
                                            );
                                        case "importador":
                                            return (
                                                <Route
                                                    key={elemento.url}
                                                    path={elemento.url}
                                                    component={Import}
                                                />
                                            );

                                        default:
                                            return null;
                                    }
                                })
                            }
                            {
                                <Route path="/eventos-e-noticias/:id" component={Noticia} />
                            }
                            {funcionalidades.length === 0 && <Route path={"/"} component={ErroAcesso} />}
                            <Route render={() => <Redirect to="/dashboards" />} />
                        </Switch>
                        {loader && <Loader overlay fixed />}
                    </Container>
                </Row>
            );
        } else if (!verificandoLocal) {
            return (
                <Switch>
                    <Route path={'/login'} component={Login} />
                    <Route path='*' render={() => <Redirect to="/login" />} />
                </Switch>
            );
        }
    }

    render() {
        return (
            <Router basename={process.env.REACT_APP_BROWSER_BASENAME}>
                <VerificarErroInterno>
                    {this.verificarAutenticacao()}
                    <Alert />
                    <ModalConfirm
                        size={'sm'}
                        modalCentered
                        modalBodyClass="modal-map-body"
                        modalClass="modal-confirm"
                    />
                </VerificarErroInterno>
            </Router>
        );
    }
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
    funcionalidades: state.core.funcionalidades,
    verificandoLocal: state.auth.verificandoLocal,
    sidebarAtivo: state.core.sidebarAtivo,
    loader: state.subFuncionalidades.loader,
})

const mapDispatchToProps = (dispatch) => {
    return {
        alterarTamanhoJanela: (window) => dispatch(actionsRedux.alterarTamanhoJanela(window)),
        ativarAlert: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
        estadoDaAutenticacao: () => dispatch(actionsRedux.estadoDaAutenticacao()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
