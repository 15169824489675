import { axiosApi } from '../../Basicos/axiosInstances'
import * as actionTypes from './tiposActions';
import { ativarAlert } from './index'
import * as actionsRedux from '../../Store/Actions/index'

export const iniciarAutenticao = (verificandoLocal = false) => {
    return {
        verificandoLocal,
        type: actionTypes.INICIO_AUTH
    };
};

export const alterarEstadoRecuperacaoToken = (loader, valido) => {
    return {
        loader: loader,
        valido: valido,
        type: actionTypes.ESTADO_RECUPERACAO_TOKEN
    }
};

export const autenticacaoAceita = (token, refreshToken = undefined) => {
    axiosApi.defaults.headers.common['authorization'] = 'Bearer ' + token
    return {
        type: actionTypes.SUCESSO_AUTH,
        token: token,
        refreshToken: refreshToken,
    };
};

export const autenticacaoRejeitada = (erro) => {
    return {
        type: actionTypes.FALHOU_AUTH,
        erro: erro
    };
};

export const limparErro = () => {
    return {
        type: actionTypes.FALHOU_AUTH,
        erro: undefined
    }
};

export const limparStorage = () => {
    axiosApi.defaults.headers.common['authorization'] = '';
    localStorage.clear();
    return {
        type: actionTypes.LOGOUT_AUTH
    };
};

export const logout = () => {
    return (dispatch) => {
        dispatch(limparStorage());
        axiosApi.get('/logout')
            .catch(error => {
                // console.log(error.response)
            })
            .finally(response => {
                window.location.reload();
            });
    }
};

export const refreshToken = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const autenticar = (usuario, senha) => {
    return (dispatch) => {
        dispatch(iniciarAutenticao());
        const dados = {
            login: usuario,
            senha: senha
        };

        axiosApi.post('/autenticacao/secretaria/login', dados)
            .then(response => {
                const { expires_in, access_token, funcionalidades, nome } = response.data;
                const dataExpiracao = new Date(new Date().getTime() + expires_in * 1000);
                localStorage.setItem('token_secretaria', access_token);
                localStorage.setItem('dataExpiracao_uni', dataExpiracao);
                localStorage.setItem('funcionalidades_uni', JSON.stringify(funcionalidades));
                localStorage.setItem('periodoLetivo_id', undefined);
                localStorage.setItem('curso_id', undefined);
                localStorage.setItem('nome', nome);
                dispatch(actionsRedux.inicializarCore(funcionalidades));
                dispatch(actionsRedux.inicializarSubFuncionalidade(funcionalidades));
                dispatch(autenticacaoAceita(access_token));
                dispatch(refreshToken(expires_in));
            })
            .catch(error => {
                // console.log(error)
                if (error.response && error.response.data) {
                    dispatch(autenticacaoRejeitada(error.response.data.erro));
                } else {
                    dispatch(autenticacaoRejeitada('Sem resposta do servidor'));
                }
                dispatch(ativarAlert('login', 'erro'))
            });
    };
};

export const autenticarComToken = (token) => {
    return (dispatch) => {
        dispatch(iniciarAutenticao());

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axiosApi.get('/autenticacao/dados-usuario', config)
            .then(response => {
                const { expires_in, access_token, funcionalidades, nome } = response.data;
                const dataExpiracao = new Date(new Date().getTime() + expires_in * 1000);
                localStorage.setItem('token_secretaria', access_token);
                localStorage.setItem('dataExpiracao_uni', dataExpiracao);
                localStorage.setItem('funcionalidades_uni', JSON.stringify(funcionalidades));
                localStorage.setItem('periodoLetivo_id', undefined);
                localStorage.setItem('curso_id', undefined);
                localStorage.setItem('nome', nome);
                dispatch(actionsRedux.inicializarCore(funcionalidades));
                dispatch(actionsRedux.inicializarSubFuncionalidade(funcionalidades));
                dispatch(autenticacaoAceita(access_token));
                dispatch(refreshToken(expires_in));
                window.location.reload();
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    dispatch(autenticacaoRejeitada(error.response.data.erro));
                } else {
                    dispatch(autenticacaoRejeitada('Sem resposta do servidor'));
                }
                dispatch(ativarAlert('loginWithToken', 'erro'));

                setTimeout(() => {
                    window.location.href = 'https://portalunichristussb.azurewebsites.net/';
                    return null;
                }, 3000);
            });
    };
};

export const recuperarSenha = (usuario, history) => {
    return (dispatch) => {
        dispatch(iniciarAutenticao());
        const dados = {
            email: usuario,
        };

        axiosApi.post('/password', dados)
            .then(response => {
                history.push('/login')
                dispatch(autenticacaoRejeitada()); // autenticação rejeitada foi usado aqui para apenas parar o loader nada mais
                dispatch(ativarAlert('recuperar-senha', 'sucesso'))
            })
            .catch(error => {
                dispatch(autenticacaoRejeitada()); // autenticação rejeitada foi usado aqui para apenas parar o loader nada mais
                dispatch(ativarAlert('recuperar-senha', 'erro'))

            });
    };
};

export const validarRecuperacaoToken = (token, history) => {
    return (dispatch) => {
        dispatch(iniciarAutenticao());
        axiosApi.get('/password/' + token)
            .then(response => {
                dispatch(alterarEstadoRecuperacaoToken(false, true));
            })
            .catch(error => {
                history.push('/login')
                dispatch(alterarEstadoRecuperacaoToken(false, false));
            });
    };
};

export const mudarSenha = (usuario, senha, senha_confirmation, token, history) => {
    return (dispatch) => {
        dispatch(iniciarAutenticao());
        const dados = {
            email: usuario,
            senha: senha,
            senha_confirmation: senha_confirmation,
            token: token
        };

        axiosApi.put('/password', dados)
            .then(response => {
                history.push('/login')
                dispatch(alterarEstadoRecuperacaoToken(false, false));
                dispatch(ativarAlert('mudar-senha', 'sucesso'))
            })
            .catch(error => {
                dispatch(alterarEstadoRecuperacaoToken(false, true));
                dispatch(ativarAlert('mudar-senha', 'erro'))
            });
    };
};

export const redirecionar = (url) => {
    return {
        type: actionTypes.REDIRECIONAR_AUTH,
        url: url
    };
};

export const estadoDaAutenticacao = () => {
    return dispatch => {
        dispatch(iniciarAutenticao(true))
        const token = localStorage.getItem('token_secretaria');
        if (!token) {
            dispatch(limparStorage());
        } else {
            const expirationDate = new Date(localStorage.getItem('dataExpiracao_uni'));
            if (expirationDate <= new Date()) {
                dispatch(limparStorage());
            } else {
                const funcionalidades = JSON.parse(localStorage.getItem("funcionalidades_uni"));
                dispatch(actionsRedux.inicializarCore(funcionalidades));
                dispatch(actionsRedux.inicializarSubFuncionalidade(funcionalidades));
                dispatch(autenticacaoAceita(token));
                dispatch(refreshToken((expirationDate.getTime() - new Date().getTime()) / 1000));
                dispatch(actionsRedux.setCurso(parseInt(localStorage.getItem('curso_id'))))
                dispatch(actionsRedux.setPeriodoLetivo(parseInt(localStorage.getItem('periodoLetivo_id'))))
            }
        }
    };
};

export const setCurso = (curso) => {
    localStorage.setItem('curso_id', curso);
    return {
        type: actionTypes.CURSO,
        cursoId: curso
    }
}

export const setPeriodoLetivo = (periodoLetivo) => {
    localStorage.setItem('periodoLetivo_id', periodoLetivo);
    return {
        type: actionTypes.PERIODO_LETIVO,
        periodoLetivoId: periodoLetivo
    }
}