import React, { Fragment, useEffect, useState } from 'react';
import { Col, Button, Row } from "reactstrap";
import { SelectForm, DateForm, TableWithVirtualizedRow } from "../../Basicos/index";
import CardHoc from "../../Hoc/cardHoc";
import { connect } from 'react-redux';
import * as actionsRedux from '../../Store/Actions/index';
import { axiosApi } from '../../Basicos/axiosInstances';
import { parseDate, formatLaravelDate } from '../../Basicos/funcoes'
import { AiFillFileExcel } from 'react-icons/ai';
import FileServer from 'file-saver';

function CardAgenda({
    ativar,
    subFuncionalidade: {
        url,
        tipoatividade,
        descricao
    },
}) {
    const [temaID, setTemaID] = useState();

    //Alimenta as opções
    const [periodoLetivoOptions, setPeriodoLetivoOptions] = useState();
    const [semestreOptions, setSemestreOptions] = useState();
    const [cursoOptions, setCursoOptions] = useState();
    const [temasOptions, setTemasOptions] = useState();
    const [tipoAtividadeOptions, setTipoAtividadeOptions] = useState();
    const statusAula = [
        {
            "label": "Todas",
            "value": "todas"
        },
        {
            "label": "Ativa",
            "value": false
        },
        {
            "label": "Cancelada",
            "value": true
        }
    ]

    //Guarda o valor selecionado pelo usuário
    const [valorPeriodoLetivo, setValorPeriodoLetivo] = useState();
    const [valorCurso, setValorCurso] = useState();
    const [valorTema, setValorTema] = useState();
    const [valorTipoAtividade, setValorTipoAtividade] = useState([]);
    const [valorStatusAula, setValorStatusAula] = useState();
    const [semestreIds, setSemestreIds] = useState([]);
    const [dataInicio, setDataInicio] = useState();
    const [dataFim, setDataFim] = useState();

    //Informações retornadas do filtro
    const [disponiveis, setDisponiveis] = useState([]);

    const handleGrupo = (value) => {
        setSemestreIds(value);
        loadTemasOptions(value);
    }

    const handleAtividade = (value) => {
        setValorTipoAtividade(value)
    }

    const loadTemasOptions = async (semestresIds) => {
        const response = await axiosApi.get(`temas-semestre?semestresIds[]=${semestresIds}`);
        setTemasOptions(response.data.dados.map(element => ({ label: element.nome, value: element.id })))
    }

    const loadTiposAtividades = async (curso) => {
        const response = await axiosApi.post(curso ? `auxiliares/tipo-de-atividade/tipo-atividade-curso?${curso}` : 'auxiliares/tipo-de-atividade/tipo-atividade-curso');
        setTipoAtividadeOptions(response.data.estrutura[0].options.map(element => ({ label: element.label, value: element.value })))
    }

    const loadInfosAgenda = async () => {
        const response = await axiosApi.get(`semana-padrao/filtro-agenda`);
        gerarSelectOptions(response.data.filtros.cursos, response.data.filtros.periodos_letivos, response.data.filtros.semestres);
    }

    const gerarSelectOptions = (cursos, periodos_letivos, semestres) => {
        setPeriodoLetivoOptions(periodos_letivos && periodos_letivos.map(element => ({ label: element.descricao, value: element.id })));
        setSemestreOptions(semestres)
        setCursoOptions(cursos && cursos.map(element => ({ label: element.nome, value: element.id })));
        setValorCurso({ label: "MEDICINA", value: 17 });
        setTipoAtividadeOptions(tipoatividade && tipoatividade.map(element => ({ label: element.nome, value: element.id })));
        setValorTema({ value: 'selecione', label: 'Selecione' });
        setValorTipoAtividade([]);
        setValorStatusAula({ value: 'selecione', label: 'Selecione' });
        loadTiposAtividades('curso_id' + '=' + '17')
    }

    const onChangeHorario = (intervalo, valores) => {
        if (valores.from != undefined)
            setDataInicio(valores.from)
        if (valores.to != undefined)
            setDataFim(valores.to)
    };

    useEffect(() => {
        loadInfosAgenda();
    }, []);

    const onChangeSelect = (nome, valor) => {
        switch (nome) {
            case 'curso_id':
                let curso = cursoOptions.find((element) => element.value === valor);
                setValorCurso(curso)
                break;
            case 'periodo_letivo_id':
                let periodoLetivo = periodoLetivoOptions.find((element) => element.value === valor);
                setValorPeriodoLetivo(periodoLetivo)
                break;
            case 'tema_id':
                let tema = temasOptions.find((element) => element.value === valor);
                setValorTema(tema)
                setTemaID(tema.value);
                break;
            case 'status_aula':
                let status = statusAula.find((element) => element.value === valor);
                setValorStatusAula(status)
                break;
            default:
                break;
        }
    }

    const getDadosFiltrados = async () => {
        if (valorPeriodoLetivo == undefined)
            ativar(400, 'Selecione um período letivo!')
        else if (dataInicio == undefined)
            ativar(400, 'Selecione uma data inicial!')
        else if (dataFim == undefined)
            ativar(400, 'Selecione uma data final!')
        else {
            const response = await axiosApi.get(`semana-padrao/agenda?tipo_atividade_id[]=${valorTipoAtividade}&periodo_letivo_id=${valorPeriodoLetivo.value}&semestresIds[]=${semestreIds}&curso_id=${valorCurso.value}&tema_id=${valorTema.value}&data_inicio=${dataInicio}&data_fim=${dataFim}&status_aula=${valorStatusAula.value}`)
            setDisponiveis(response.data.dados)
        }
    }

    const limparFiltro = () => {
        setValorCurso(undefined);
        setValorPeriodoLetivo(undefined);
        setSemestreIds([]);
        setDataInicio(undefined);
        setDataFim(undefined);
        setDisponiveis([]);
        setValorTema({ value: 'selecione', label: 'Selecione' });
        setValorTipoAtividade([]);
        setValorStatusAula({ value: 'selecione', label: 'Selecione' });
    }

    const getExportDownload = async () => {
        try {
            let params = {
                tipo_atividade_id: valorTipoAtividade,
                periodo_letivo_id: valorPeriodoLetivo.value,
                semestresIds: semestreIds,
                curso_id: valorCurso.value,
                tema_id: valorTema.value,
                data_inicio: dataInicio,
                data_fim: dataFim,
                status_aula: valorStatusAula.value
            }
            // setIsLoading(true);
            await axiosApi.post(`exportacao/xlsx/agenda?`, params, {
                responseType: 'blob',
            }).then((res) => {
                const fileName = `Agenda.xlsx`;
                const file = new File([res.data], fileName, { type: `${res?.data?.Blob?.type};charset=utf-8` });
                FileServer.saveAs(file);
            });
        } catch (error) {
            console.log(error);
        } finally {
            // setIsLoading(false);
        }
    }

    const semestresIdsOptions = () => semestreOptions?.map(grupo => ({ value: grupo.id, label: grupo.descricao }));
    
    const atividadesIdsOptions = () => tipoAtividadeOptions?.map(atividade => ({ value: atividade.value, label: atividade.label }));

    const semestreIdsValue = () => semestresIdsOptions()?.filter(elemento => semestreIds?.includes(elemento.value));
    
    const atividadesValue = () => atividadesIdsOptions()?.filter(elemento => valorTipoAtividade?.includes(elemento.value));

    return (
        <Fragment>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="d-flex mb-3 header-legenda text-header">
                <h3><strong> {descricao.toUpperCase()} </strong></h3>
            </Col>
            <Col sm={12} md={12}>
                <Row className="pr-5 px-3">
                    <SelectForm
                        label="Curso*"
                        name="curso_id"
                        placeholder="Curso"
                        options={cursoOptions}
                        onChange={onChangeSelect}
                        value={valorCurso ?? ''}
                        style={
                            {
                                col: {
                                    sm: 2,
                                    xs: 2,
                                    md: 2,
                                    lg: 2,
                                    xl: 2
                                }

                            }}
                    />
                    <SelectForm
                        label="Período Letivo*"
                        name="periodo_letivo_id"
                        placeholder="Período Letivo"
                        options={periodoLetivoOptions}
                        onChange={onChangeSelect}
                        value={valorPeriodoLetivo ?? ''}
                        style={
                            {
                                col: {
                                    sm: 2,
                                    xs: 2,
                                    md: 2,
                                    lg: 2,
                                    xl: 2
                                }

                            }}
                    />
                    <SelectForm
                        telaAgenda={true}
                        label="Semestre"
                        name="semestres_ids"
                        placeholder="Semestre"
                        value={semestreIdsValue()}
                        options={semestresIdsOptions()}
                        onChange={(input, value) => handleGrupo(value)}
                        isMulti
                        style={
                            {
                                col: {
                                    sm: 2,
                                    xs: 4,
                                    md: 4,
                                    lg: 4,
                                    xl: 4
                                }

                            }}
                    />
                    <SelectForm
                        label="Tema"
                        name="tema_id"
                        placeholder="Tema"
                        options={temasOptions}
                        onChange={onChangeSelect}
                        readOnly={!valorTema}
                        value={valorTema ?? ''}
                        style={
                            {
                                col: {
                                    sm: 2,
                                    xs: 2,
                                    md: 2,
                                    lg: 2,
                                    xl: 2
                                }

                            }}
                    />
                    <SelectForm
                        label="Status Aula"
                        name="status_aula"
                        placeholder="Status da Aula"
                        options={statusAula}
                        onChange={onChangeSelect}
                        readOnly={!valorStatusAula}
                        value={valorStatusAula ?? ''}
                        style={
                            {
                                col: {
                                    sm: 2,
                                    xs: 2,
                                    md: 2,
                                    lg: 2,
                                    xl: 2
                                }

                            }}
                    />

                    <SelectForm
                        telaAgenda={true}
                        label="Tipo de Atividade"
                        name="tipo_de_atividade_id"
                        placeholder="Atividade"
                        value={atividadesValue()}
                        options={atividadesIdsOptions()}
                        onChange={(input, value) => handleAtividade(value)}
                        isMulti
                        style={
                            {
                                col: {
                                    xl: 3
                                },
                            }}
                    />
                    <DateForm
                        name="intervalo_data"
                        label="Tipo de Marcador"
                        style={{ labelClass: "label-centralizado", telaAgenda: true }}
                        onChange={onChangeHorario}
                        dataInicial={{ "label": "Data Inicial*", ...dateInicialFormStyle, diasFuturos: true }}
                        dataFinal={{ "label": "Data Final*", ...dateFinalFormStyle, diasFuturos: true }}
                        value={{ from: parseDate(dataInicio, formatLaravelDate), to: parseDate(dataFim, formatLaravelDate) }}
                    />
                    <Col className="btn-limpar-filtro-agenda">
                        <Button
                            className="mx-3 py-2 pesquisar-agenda"
                            onClick={getDadosFiltrados}
                        >Pesquisar</Button>
                    </Col>
                    <Col className="btn-limpar-filtro-agenda">
                        <Button
                            className="mx-3 py-2 pesquisar-agenda"
                            onClick={limparFiltro}
                        >Limpar</Button>
                    </Col>
                </Row>
            </Col>
            <Col xs={13} sm={13} md={13} lg={13} xl={13} className={'pr-5'}>
                <TableWithVirtualizedRow
                    tabelaClassName="tabelaConsulta"
                    columns={[
                        {
                            Header: 'Período Letivo',
                            accessor: 'periodo_letivo',
                            disabled: true,
                            style: { width: '150px' },
                        },
                        {
                            Header: 'Semestre',
                            accessor: 'semestre',
                            disabled: true,
                            style: { width: '100px' },
                        },
                        {
                            Header: 'Tema',
                            accessor: 'tema',
                            disabled: true,
                            style: { width: '250px' },
                        },
                        {
                            Header: 'Tipo de Atividade',
                            accessor: 'tipo_atividade',
                            disabled: true,
                            style: { width: '200px' },
                        },
                        {
                            Header: 'Aula',
                            accessor: 'titulo',
                            disabled: true,
                            style: { width: '400px' },
                        },
                        {
                            Header: 'CH',
                            accessor: 'duracao_aula',
                            disabled: true,
                            style: { width: '50px' },
                        },
                        {
                            Header: 'Data',
                            accessor: 'data_ptbr',
                            disabled: true,
                            style: { width: '110px' },
                        },
                        {
                            Header: 'Hora Início',
                            accessor: 'horario_inicio',
                            disabled: true,
                            style: { width: '110px' },
                        },
                        {
                            Header: 'Professor',
                            accessor: 'professores',
                            disabled: true,
                            style: { width: '230px' },
                        },
                        {
                            Header: 'Local',
                            accessor: 'local',
                            style: { width: '200px' },
                            disabled: true,
                        },
                        {
                            Header: 'Status',
                            accessor: 'status',
                            style: { width: '90px' },
                            disabled: true,
                        },
                        {
                            Header: 'Turma',
                            accessor: 'turma',
                            style: { width: '90px' },
                            disabled: true,
                        },
                        {
                            Header: 'Grupos',
                            accessor: 'grupos',
                            style: { width: '150px' },
                            disabled: true,
                        },
                        {
                            Header: 'Observação',
                            accessor: 'observacao',
                            style: { width: '150px' },
                            disabled: true,
                        }
                    ]}
                    data={disponiveis}
                />
                {
                    disponiveis.length > 0 ?
                        <Button
                            className="float-right export-btnConsulta"
                            size="sm"
                            onClick={() => getExportDownload('xlsx')}
                        >
                            <AiFillFileExcel size="1.2rem" style={{ padding: '1px' }} />
                            Exportar XLSX
                        </Button>
                        : ""
                }
            </Col>
        </Fragment>
    );
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        atualizarDadosRemotamente: (params) => dispatch(actionsRedux.atualizarSubFuncionalidade(ownProps.funcionalidade, ownProps.indiceSubFuncionalidade, params)),
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
    }
}

const mapStateToProps = (state) => {
    return {
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardHoc(CardAgenda))

const dateInicialFormStyle = {
    "placeholder": "Data Inicial",
    "style": {
        "labelClass": "label-conteudoInferior",
        "elementoClass": "",
        "col": {
            "sm": 3
        }
    }
}
const dateFinalFormStyle = {
    "placeholder": "Data Final",
    "style": {
        "labelClass": "label-conteudoInferior",
        "elementoClass": "",
        "col": {
            "sm": 3
        }
    }
}