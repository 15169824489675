import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { MenuDasFuncionalidades } from "../Menu";
import { connect } from 'react-redux';
import * as actionsRedux from '../Store/Actions/index';
import { CardConsulta } from "../Conteudo/Cards";
import { ChaveamentoDeModal } from "../Modal";
import { axiosApi } from '../Basicos/axiosInstances';

function Consultas({
    consultas,
    estadoModal,
    desativarModal,
    adicionar,
    editar,
    estruturaModal,
    ativarAlert
}) {
    const { componentesEsquerda, componentesDireita } = gerarCards(consultas);
    
    const getFuncionalidades = async () => {
        try {
            const response = await axiosApi.get('funcionalidades/update');
            const { funcionalidades } = response.data;
            const funcionalidadesLocal = localStorage.getItem('funcionalidades_uni');

            if (funcionalidades && JSON.stringify(funcionalidades) !== funcionalidadesLocal) {
                localStorage.setItem('funcionalidades_uni', JSON.stringify(funcionalidades));
                window.location.reload();
            }
            if (funcionalidades && funcionalidades[10]?.subfuncionalidades?.length === 0) {
                ativarAlert(400, "Acesso não autorizado! Entre em contato com o Administrador da Plataforma");
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getFuncionalidades();
    }, []);
    
    return (
        <Row noGutters>
            <MenuDasFuncionalidades
                funcionalidade={"consultas"}
                titulo="Consultas"
            />

            <Col sm={12} md={12} className="col-conteudo">
                <Row>
                    <Col sm={12} md={12} lg={6}>{componentesEsquerda}</Col>
                    <Col sm={12} md={12} lg={6}>{componentesDireita}</Col>
                </Row>
                <ChaveamentoDeModal
                    boolModal={estadoModal}
                    toggle={desativarModal}
                    adicionar={adicionar}
                    editar={editar}
                    estrutura={estruturaModal}
                />
            </Col>
        </Row>
    )
}

const gerarCards = (consultas) => {
    const componentesEsquerda = [];
    const componentesDireita = [];
    consultas.forEach((subfuncionalidadeIndice, indice) => {
        if (indice % 2 === 0) {
            componentesEsquerda.push(
                <CardConsulta
                    key={indice}
                    indice={indice}
                    className="cards-menores"
                    nomeButton={"Consultar"}
                    funcionalidade={'consultas'}
                    collapse
                    header
                />
            );
        } else {
            componentesDireita.push(
                <CardConsulta
                    key={indice}
                    indice={indice}
                    className="cards-menores"
                    nomeButton={`${subfuncionalidadeIndice?.descricao == "Termos de Compromisso" || subfuncionalidadeIndice?.descricao == "Termos de Compromisso - Rodízio Manual" ? "Gerar PDF" : "Consultar"}`}
                    funcionalidade={'consultas'}
                    collapse
                    header
                />
            );
        }
    });

    return { componentesEsquerda, componentesDireita };
}

const mapDispatchToProps = (dispatch) => {
    return {
        desativarModal: () => dispatch(actionsRedux.desativarModalSubFuncionalidade()),
        ativarAlert: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao))
    }
}

const mapStateToProps = (state) => {
    return {
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
        adicionar: state.subFuncionalidades.adicionar,
        editar: state.subFuncionalidades.editar,
        consultas: state.subFuncionalidades.consultas
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Consultas);
