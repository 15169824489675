import React from 'react'
import { Row, Col } from 'reactstrap';
import { Redirect, Switch, Route } from "react-router-dom";
import { connect } from 'react-redux';
import * as actionsRedux from '../Store/Actions/index';
import { convertToSlug } from '../Basicos/funcoes';
import CardAgenda from '../Conteudo/Cards/CardAgenda';
import CalendarioAvaliacaoAnalitica from '../Conteudo/CalendarioAvaliacaoAnalitica';
import CalendarioAvaliacaoSintetica from '../Conteudo/CalendarioAvaliacaoSintetica';
import CalendarioSemanaSintetica from '../Conteudo/CalendarioSemanaSintetica';

function SemanaPadrao({ ofertasPeriodoLetivo, }) {
    return (
        <Row noGutters={true}>

            <Switch>
                {ofertasPeriodoLetivo.map((subFuncionalidade, indice) => {
                    switch (convertToSlug(subFuncionalidade.descricao)) {
                        case "agenda":
                            return <Route
                                key={subFuncionalidade.url}
                                path={subFuncionalidade.url}
                                render={(props) => <Col sm={12} md={12} className="col-conteudo"><CardAgenda
                                    {...props}
                                    subFuncionalidade={subFuncionalidade}
                                    indiceSubFuncionalidade={indice}
                                    funcionalidade={"agenda"}
                                /></Col>
                                }
                            />;
                        case "visualizar-avaliacao-analitica":
                            return <Route
                                key={subFuncionalidade.url}
                                path={subFuncionalidade.url}
                                render={(props) => <Col sm={12} md={12} className="col-conteudo avaliacao-analitica2"><CalendarioAvaliacaoAnalitica
                                    {...props}
                                    subFuncionalidade={subFuncionalidade}
                                    indiceSubFuncionalidade={indice}
                                    funcionalidade={"visualizar-avaliacao-analitica"}
                                /></Col>
                                }
                            />;
                        case "visualizar-avaliacao-sintetica":
                            return <Route
                                key={subFuncionalidade.url}
                                path={subFuncionalidade.url}
                                render={(props) => <Col sm={12} md={12} className="col-conteudo avaliacao-analitica"><CalendarioAvaliacaoSintetica
                                    {...props}
                                    subFuncionalidade={subFuncionalidade}
                                    indiceSubFuncionalidade={indice}
                                    funcionalidade={"visualizar-avaliacao-sintetica"}
                                /></Col>
                                }
                            />;
                        case "visualizar-semana-sintetica":
                            return <Route
                                key={subFuncionalidade.url}
                                path={subFuncionalidade.url}
                                render={(props) => <Col sm={12} md={12} className="col-conteudo avaliacao-analitica"><CalendarioSemanaSintetica
                                    {...props}
                                    subFuncionalidade={subFuncionalidade}
                                    indiceSubFuncionalidade={indice}
                                    funcionalidade={"visualizar-semana-sintetica"}
                                /></Col>
                                }
                            />;
                        default:
                            return (
                                null
                            );
                    }
                })}
                <Route render={() => <Redirect to="/" />} />
            </Switch>
        </Row>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        desativarModal: () => dispatch(actionsRedux.desativarModalSubFuncionalidade()),
    }
}

const mapStateToProps = (state) => {
    return {
        ofertasPeriodoLetivo: state.subFuncionalidades['semana-padrao'],
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
        adicionar: state.subFuncionalidades.adicionar,
        editarFunc: state.subFuncionalidades.editar,
        tipoAcao: state.subFuncionalidades.tipoAcao,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SemanaPadrao)