import React, { useState, Fragment, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Col, Label, Button } from "reactstrap";
import { Estruturas, Loader, NewLoader } from "../../Basicos";
import CardHoc from "../../Hoc/cardHoc";
import { connect } from 'react-redux';
import * as actionsRedux from '../../Store/Actions/index';
import { axiosApi } from '../../Basicos/axiosInstances';
// import { Document, Page, pdfjs } from 'react-pdf';
// import pdfjs from 'pdfjs-dist';
// import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

function CardConsulta(props) {
    const [isLoading, setIsLoading] = useState(false);
    const requisicao = useRef(false);
    const formulario = useRef(null);
    const [submit, setSubmit] = useState(false);
    const atualizarFiltroRemotamente = useCallback(props.atualizarFiltroRemotamente, []);

    useEffect(() => {
        if (!requisicao.current && props.collapse) {
            atualizarFiltroRemotamente();
            requisicao.current = true
        }
    }, [props.collapse, atualizarFiltroRemotamente]);

    const consultar = async () => {
        let params = Object.assign({}, formulario.current.formulario)
        if (!formulario.current.state.incompleto) {
            if (props?.tituloHeader == "Termos de Compromisso" || props?.tituloHeader == "Termos de Compromisso - Rodízio Manual") {
                setIsLoading(true)
                try {
                    params.formatoArquivo = "pdf";

                    const routers = { 
                        'Termos de Compromisso': '/consultas/termos-compromisso',
                        'Termos de Compromisso - Rodízio Manual': '/consultas/rodizio-manual',
                    };

                    const response = await axiosApi.get(routers[props.tituloHeader], { params })
                    window.open(response.data.data, '_blank');
                    return true;
                } catch (error) {
                    props.ativar(error.response.status, error.response.data.erro);
                } finally {
                    setIsLoading(false)
                }
            } else {
                props.gerarConsulta(params);
            }
        }
        setSubmit(true)
    }

    return (
        <NewLoader isActive={isLoading} overlay >
            <Col xs={12} sm={12} >
                {props.estrutura ? (
                    <Fragment>
                        <Label xs={12} sm={12}><b>{props.titulo}</b></Label>
                        <Estruturas
                            estrutura={props.estrutura}
                            tipo={"forms"}
                            ref={formulario}
                            submit={submit}
                        />
                        <Button
                            className="float-right margin-btnConsulta"
                            onClick={consultar}
                            size="sm"
                        >
                            {props.nomeButton}
                        </Button>
                    </Fragment>
                ) : (
                    <Loader overlay={props.loader} padding />
                )}
            </Col>
        </NewLoader>
    )
}

CardConsulta.propTypes = {
    titulo: PropTypes.string,
    className: PropTypes.string.isRequired,
    nomeButton: PropTypes.string.isRequired,
    estrutura: PropTypes.array
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const consulta = ownProps.funcionalidade !== 'exportador' ? (params) => dispatch(actionsRedux.ativarModalSubFuncionalidade(ownProps.funcionalidade, ownProps.indice, 'relatorio', null, params)) : (params) => dispatch(actionsRedux.exportarSubfuncionalidade(ownProps.funcionalidade, ownProps.indice, params));
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
        gerarConsulta: consulta,
        atualizarFiltroRemotamente: () => dispatch(actionsRedux.buscarFiltrosSubFuncionalidade(ownProps.funcionalidade, ownProps.indice)),
        exportar: (params) => dispatch(actionsRedux.exportarSubfuncionalidade(ownProps.funcionalidade, ownProps.indice, params)),

    }
}

const mapStateToProps = (state, ownProps) => {
    const subFuncionalidade = state.subFuncionalidades[ownProps.funcionalidade][ownProps.indice];
    return {
        tituloHeader: subFuncionalidade.descricao,
        estrutura: subFuncionalidade.estrutura,
    }
}

var consulta = CardHoc(React.memo(CardConsulta))
export default connect(mapStateToProps, mapDispatchToProps)(consulta)
