import React, { PureComponent, Fragment } from 'react'
import { Col, Label, FormFeedback, FormGroup } from "reactstrap"
import PropTypes from 'prop-types'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { FaRegCalendarAlt } from 'react-icons/fa';
import dateFnsFormat from 'date-fns/format';
import addDays from 'date-fns/addDays';
import { formatoData, formatLaravelTimestamp, formatLaravelDate, parseDate, formatDate, pt_br } from '../funcoes'
/**********************************************************************************************************************************************/
//Componente responsavel por configurar um date do react-day-picker de acordo com a necessidade de gerar formulario dinamicos para supervisor//
/********************************************************************************************************************************************/
// POR PADRÃO TODA DATA É FORMATADA PARA O FORMATO BRASILEIRO

class DateForm extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            from: parseDate(this.props.dataInicial.valorPadrao, formatLaravelTimestamp),
            to: parseDate((this.props.dataFinal) ? this.props.dataFinal.valorPadrao : undefined, formatLaravelTimestamp),
            manipuladoPeloUsuario: false,
            manipuladoPeloUsuarioTo: false,
            telaAgenda: props.style?.telaAgenda ?? false
        }

        if (this.props.dataFinal && !this.props.dataFinal.style) {
            this.props.dataFinal.style = style
        }

    }

    isValid = (final) => {
        if (final)
            return ((this.state.manipuladoPeloUsuarioTo || this.props.submit) && this.props.invalido && !this.state.to)
        else
            return ((this.state.manipuladoPeloUsuario || this.props.submit) && this.props.invalido && !this.state.from)
    }

    changeClass = (final = false) => {
        if (this.isValid(final)) {
            return {
                width: "100%",
                border: "1px solid red",
                padding: ".375rem .75rem",
                boxSizing: "border-box",

                borderRadius: '0.7rem',
                height: 'calc(1.5em + .75rem)',
            }
        } else {
            return {
                width: "100%",
                border: "1px solid #ced4da",
                padding: ".375rem .75rem",
                boxSizing: "border-box",

                borderRadius: '0.7rem',
                height: 'calc(1.5em + .75rem)',
            }
        }
    }

    onChange = (tipo, valor) => {
        const { from, to } = this.props.value ? this.props.value : this.state;
        if (tipo === "data_inicial") {
            if (this.props.dataFinal) {
                this.setState({ from: valor, to: this.props.dataFinal.intervalo ? undefined : to });
                const toFormated = this.props.dataFinal.intervalo ? undefined : formatDate(to, formatLaravelDate);
                this.props.onChange(this.props.name, { from: formatDate(valor, formatLaravelDate), toFormated });
            } else {
                this.setState({ from: valor });
                this.props.onChange(this.props.name, formatDate(valor, formatLaravelDate));
            }
        } else {
            this.setState({ to: valor });
            this.props.onChange(this.props.name, { from: formatDate(from, formatLaravelDate), to: formatDate(valor, formatLaravelDate) });
        }
    }

    render() {
        const { from, to } = this.props.value ? this.props.value : this.state;
        const modifiers = { start: from, end: to };
        const diasFuturos = this.props.dataInicial.diasFuturos ? undefined : new Date()
        const dayPickerProps = (this.props.dataFinal) ? { selectedDays: [from, { from, to }], disabledDays: { after: to }, modifiers, onDayClick: () => !to && this.to.getInput().focus() } : { disabledDays: { after: diasFuturos } }
        return (
            <Fragment>
                <Col {...this.props.dataInicial.style.col} className="form-col">
                    <FormGroup className={(this.props.hidden ? "hidden" : "")}>
                        <Label className={this.props.dataInicial.style.labelClass + " label"}>
                            {this.props.dataInicial.iconLabel && <FaRegCalendarAlt size="18px" style={{ marginRight: "10px" }} />}
                            {this.props.dataInicial.label + ":"}
                        </Label>
                        {/* <FormGroup className = "form-group-feedback-input"> */}
                        <DayPickerInput placeholder={this.props.dataInicial.placeholder}
                            name={this.props.dataInicial.name ? this.props.dataInicial.name : `${dateFnsFormat(new Date(), formatoData)}`}
                            value={from}
                            format={formatoData}
                            formatDate={formatDate}
                            readOnly={this.props.dataInicial.readOnly}
                            parseDate={parseDate}
                            onDayChange={this.onChange.bind(this, "data_inicial")}
                            dayPickerProps={{ locale: 'pt-br', ...pt_br, ...dayPickerProps }}
                            inputProps={{
                                onBlur: () => this.setState({ manipuladoPeloUsuario: false }),
                                style: this.changeClass(),
                                disabled: this.props.dataInicial.readOnly
                            }}
                        />
                        <FormFeedback style={{ display: (this.isValid()) ? "block" : "none" }}>
                            {"Selecione uma opção valida !"}
                        </FormFeedback>
                        {/* </FormGroup> */}
                    </FormGroup>
                </Col>

                {(this.props.dataFinal) &&
                    <Col  {...this.props.dataFinal.style.col} className="form-col">
                        <FormGroup className={(this.props.hidden ? "hidden" : "")}>
                            <Label className={this.props.dataFinal.style.labelClass + " label"}>
                                {this.props.dataFinal.label + ":"}
                            </Label>
                            {/* <FormGroup className = "form-group-feedback-input"> */}
                            <DayPickerInput ref={el => (this.to = el)}
                                value={to}
                                placeholder={this.props.dataFinal.placeholder}
                                months={pt_br.months}
                                formatDate={formatDate}
                                format={formatoData}
                                parseDate={parseDate}
                                onDayChange={this.onChange.bind(this, "data_final")}
                                inputProps={{
                                    onBlur: () => this.setState({ manipuladoPeloUsuarioTo: true }),
                                    style: this.changeClass(true),
                                    disabled: this.props.dataFinal.readOnly
                                }}
                                dayPickerProps={{
                                    locale: 'pt-br',
                                    selectedDays: [from, { from, to }],
                                    disabledDays: {
                                        before: from,
                                        after: this.props.dataFinal.intervalo ? addDays(from, this.props.dataFinal.intervalo) : undefined
                                    },
                                    modifiers,
                                    month: from,
                                    fromMonth: from,
                                    ...pt_br
                                }}
                            />
                            <FormFeedback style={{ display: (this.isValid(true)) ? "block" : "none" }}>
                                Selecione uma opção valida !
                                </FormFeedback>
                            {/* </FormGroup> */}
                        </FormGroup>
                    </Col>
                }
            </Fragment>
        )
    }
}

export default DateForm

const colShape = {
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

const styleShape = {
    labelClass: PropTypes.string,
    elementoClass: PropTypes.string,
    col: PropTypes.shape(colShape)

}

const dataShape = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    intervalo: PropTypes.number,
    style: PropTypes.shape(styleShape)
}

const style = {
    labelClass: "label-conteudoInferior",
    elementoClass: "",
    col: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12
    }
}

DateForm.propTypes = {
    name: PropTypes.string.isRequired,
    innerRef: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    dataInicial: PropTypes.shape(dataShape).isRequired,
    dataFinal: PropTypes.shape(dataShape)
}

DateForm.defaultProps = {
    placeholder: "",
    dataInicial: {
        style: {}
    },
};

// configurações do React day Picker
